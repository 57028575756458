#moduleAchievement {
    display: flex;
    flex-direction: column;
    flex: 1;

    main {
        display: flex;
        flex-direction: column;
        flex: 1;
        > *:not(router-outlet) {
            display: flex;
            flex: 1 0 auto;
            width: 100%;
            flex-direction: column;
        }
    }
}

app-welcome {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}

.achievement-dialog {
    mat-dialog-container {
        margin-top: 0;
        list-style: none;
        background-color: $primary-c;
        border: $accent-c 0.25rem solid;
        border-left: none;
        border-radius: 1rem;
        color: #fff;
        .mat-mdc-dialog-content {
            display: flex;
            flex-direction: row;
            z-index: 1;
            padding: 0 5%;
            margin: 0;
            flex-wrap: wrap;

            .media {
                flex: 1 0 125px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    margin: 0;
                    padding: 0;
                    min-height: 125px;
                    min-width: 125px;
                }
            }
            .data {
                flex: 2 0 200px;
                color: #3a4354;
                h2 {
                    font-size: 24px;
                    font-weight: 700;
                    margin-top: 0;
                }
                p {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    padding: 0;
                    margin: 0 0 12px 0;
                }
            }
        }
    }
}

.openbadge-dialog {
    mat-dialog-container {
        background: image('backgrounds/modal-background.png') no-repeat right 20px;

        margin-top: 0;
        list-style: none;
        background-color: $primary-c;
        border: $accent-c 0.25rem solid;
        border-left: none;
        border-radius: 1rem;
        color: #fff;
        .mat-mdc-dialog-content {

            display: flex;
            flex-direction: row;
            z-index: 1;
            padding: 0 5%;
            margin: 0;
            flex-wrap: wrap;

            dl {
                dt {
                    display: block;
                    padding-top: 15px;
                    color:#7C859D;
                    font-weight: normal;

                    &:first-child {
                        padding-top: 0px;
                    }
                    &::after{
                        content:"";
                    }
                }
                dd.subtitle{
                    color: #3A4354;
                    font-weight: bold;
                    font-size: 20px;
                }
            }

            .media {
                flex-direction: column;
                flex: 1 0 0;
                display: flex;
                align-items: flex-start;
                justify-content: center;

                img {
                    margin: 0;
                    padding: 0;
                    min-height: 125px;
                    min-width: 125px;
                    max-width: 256px;
                }

                button{
                    margin-bottom: 3px;
                }
            }
            .data {
                margin: 0 0 0 2em;
                max-width: 800px;
                flex: 2 0 200px;
                color: #3a4354;
                h2 {
                    font-size: 24px;
                    font-weight: 700;
                    margin-top: 0;
                }
                p {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;
                    padding: 0;
                    margin: 0 0 12px 0;
                }
            }
        }

        .mat-mdc-dialog-actions{
            > div {
                background: image('backgrounds/plus.png') repeat-x;
                height: 31px;
                width: 100%;
            }
            padding: 0;
            margin: 15px 0 0;
            background: rgba(245, 244, 244, 0.05);
            border: 0.520581px solid rgba(198, 204, 215, 0.9);
            box-shadow: inset 0px 0px 20.8232px rgba(0, 0, 0, 0.08);

            ul{
                margin: 0;
                padding: 0 5px;
                width: 90%;
                list-style: none;
                display: flex;
                justify-content: space-between;
                a{
                    text-decoration: underline;
                    color:#28458C;
                }
            }

            .mat-icon{
                margin-right: 10px;
                vertical-align: middle;
                path {
                    fill: #FBAC35;
                }
            }
        }
    }
}