
 app-ideas-wall-main-view { // TODO move css to default
    flex: 1;

    .header {
        height: 74px;
        background: rgba(48, 87, 146, 0.1);
        color: #305792;
        //font-family: "nexabold";
        font-size: 22px;
        line-height: 75px;
        padding-left: 31px;
    }

    ideas-wall-remote-controller {
        z-index: 100000;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .ideas-wall {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;

        .wall-header {
            .selectors-container {
                padding: 0 10vw;
                .mode-selector {
                    padding: 10px 0;
                    cursor: pointer;
                    width: 12vw;
                    text-align: center;
                    &.selected {
                        //border-bottom: 3px solid #305792;
                        padding-bottom: 6px;
                    }
                }
            }
        }

        .main-container {
            flex: 1;
        }
    }

    .mat-mdc-mini-fab.cursor-default {
        cursor: default !important;
        &:active {
            box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
        }
    }

    .ideas-container,
    .wall-categories-container {
        height: 100%;
        display: inline-block;
        vertical-align: top;
        overflow: auto;
        position: relative;
    }

    .main-container {
        &.ideas {
            .ideas-container {
                width: 100%;
            }
        }

        &.categories {
            .wall-categories-container {
                width: 100%;
            }
        }

        &.mixed {
            .ideas-container {
                width: 70%;
            }

            .wall-categories-container {
                width: 30%;
                border-left: 1px solid #979797;
            }
        }
    }

    .placeholder {
        border: 2px dashed lightgray;
        border-radius: 4px;
        width: 300px;
        height: 60px;
        margin-left: 35px;
        margin-right: 35px;

        &.inline {
            display: inline-block;
            vertical-align: top;
            margin-top: 10px;
        }
    }
    .remote-controller-container {
        .remote-controller,
        div {
            margin: 10px 10px 0px 10px;
            background-color: #305792;
            mat-icon {
                vertical-align: middle;
                color: white;
                svg path {
                    fill: white;
                }
            }
        }
    }
}

 .ideas-wall-card-menu .mat-mdc-menu-content{
    display: flex;
    flex-direction: column;
}