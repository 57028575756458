@use 'sass:string';
@use "sass:meta";

@mixin classesGenerator($prefix, $stringList) {
    @if meta.type-of($stringList) == "map" {
        @each $key, $value in $stringList {
            @if meta.type-of($value) == map {
                #{$prefix}#{$key} {
                    @each $innerKey, $innerValue in $value {
                        #{$innerKey}:$innerValue;
                    }
                }
            }
        }
    } @else if meta.type-of($stringList) == "list" {
        @each $item in $stringList {
            @if #{$prefix} == 'bg-' {
                .#{$prefix}#{str-slice($item, 2)} {
                    background-color: string.unquote($item);
                }
            } @else if #{$prefix} == 'c-' {
                .#{$prefix}#{str-slice($item, 2)} {
                    color: string.unquote($item);
                }
            }
        }
    }
}

// version 2 : https://sass-lang.com/playground/#eJytVt9vmzAQfuevsEikJGqgLSvVCmoUJWv3sGkvk/ZOwKRWCM7AdKki/vedbQwkmCabek/x/fi+u/Mdzu3tvMgxGuVBnnsJydnIN1qanGUkXSudKXRbzALTN45UwU5o4iINGaEpAoUVYbyz1piNh3CaouEGv+W2bU/QwUAgcxyEL0KLSCqtlYULj/E4jH2MMPGFSykhMsyKLBXOvlFCBsOQbndB/iN4XRSM0XRJE5r9xMxDYxFhDh4+3TlfHHNaHd3F8uZpUR+fHffObY7uk7N4fm6sTw8Pzk19jCL3LuZQkNRwf4a5IpaxFW11kKTKIiiVRRBWB0kn2IxhhnNaZCHOl0EWLRO4BJwrKt78DamZuYQ8Gw8N4jie1spVEG7WGS3SCCxucH8f4sZIQpp6yFxRujGFcjKtwXdBFgJ7rmH480IY7qHAjht/PkPBi5tvyR5GIpRVfcUpzgJGs/Fwl+GY7GEQ5Fh+h3GV03R9jeYRXhVrNGrZ0AyN0BVibzts0XjcjvJPgiQyD2j5SyXPSGU8JzHi42/rMNHjIzL5JrTGmLNUJKal5NZqxPRbvkdhPLk1jyM52gMsmplQTC97D87xpkHrXoOkwGLjWo06nETwoCprsaAzZF4JAL/fEXkKHLx5pmp3hXIqB2SiA+g0VUSIfvK6NclxsQcHnlEJdr1DpwEkhUn6Jrogfv6qWyHzPiWCO9CiqaFReNWcNecrOLcp2nahOe3CPzLVjVL6SQ/lsaOwdG7gfe6eS+/IBS4gcGUq5dJ7px//w1DqHbrqE02pX5wSpiaBB+7sxvNH0+zMTmfpneOl72SlZpTh7QXbqetGMy0cw7Jmo873glt0K1jH8mJUqPDWZCo3Fq5Sfh9L3oXRam2NejbVblwHByjLyhMSYpnMFDmTsm/DW6+HVT0vsit2kf4uKMPvFKSfh9alnuQf9qb/wTV9TCHatHSOl8WqyNIoDRCbBSwQFcxJGiZFhLuPsQk3bsKXtOdvD9RSAg5h5BxOeAEMZfQcDP9fwoF0f4wEivEXjkWnHQ==