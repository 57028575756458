
// report du scroll sur l'interieur
app-dash-widget {
    display: flex; // s'adapte au parent
    flex-direction: column;
    overflow: auto; // conserve la hauteur du parent
    mat-card.mat-mdc-card {
        display: flex; // s'adapte au parent
        flex-direction: column;
        overflow: auto; // conserve la hauteur du parent
        mat-card-header.mat-mdc-card-header {
            flex: none; // hauteur indexé sur le contenu pas sur la répartion flex
        }
        mat-card-content.mat-mdc-card-content {
            flex: 1; // s'adapte au parent
            overflow: auto; // conserve la hauteur du parent et scroll ici
        }
    }

}

app-dash-widget .mat-mdc-card .mat-mdc-card-header button.mat-mdc-icon-button {
    // pas necessaire si ce n'était pas dans default
    //  devrait être défini dans _bouton tt simplement
    color: white;
    .mat-icon path {
        // encore plus loins unnecessary
        fill: currentColor;
    }
}