@use '@angular/material' as mat;

// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

$filters--background-color: rgba(0, 65, 87, 0.06) !default;
$filters--border: null !default;
$filters--border-radius: null !default;
$filters--color: null !default;
$filters--elevation: false !default;

$filters__button--disabled--color: rgba(0, 0, 0, 0.26) !default;
$filters__button-collapse--border-color: var(--accent-border) !default;
$filters__button-collapse--mat-icon--color: $accent-c !default;
$filters__button-collapse--mat-icon--border-color: $accent-c !default;

$search-filters__chapter-field--hidden: false !default;

// --------------------------------------------------
// STYLE
// --------------------------------------------------

.count-entities {
    color: rgba($default-font-color, 0.8);
    font: $form-field-input-font;
}

.filters {
    margin: 0 0.9rem 1.8rem !important; // Override Material style
    padding: 1.8rem 0 0.9rem;
    background-color: $filters--background-color !important; // Override Material style
    color: $filters--color;
    border: $filters--border;
    border-radius: $filters--border-radius;
    @extend %mat-form-field-label-on-top;

    @if ($filters--elevation == true) {
        @include mat.elevation(2);
    }

    .mat-mdc-form-field.mat-mdc-form-field-should-float,
    .mat-mdc-form-field.mat-mdc-form-field-can-float,
    .mat-mdc-form-field.mat-mdc-form-field-label-always-float,
    .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper {
        min-width: 260px;
        margin-right: 10px;
        
        &.field-school {
            min-width: 190px;
        }
    }

    button.mat-mdc-raised-button {

        .count {
            margin-right: -13px;
            padding: 0 5px;
        }
    }

    .btn-collapse {
        color: $default-font-color;
        border-color: $filters__button-collapse--border-color !important;
        @extend %button--basic;

        .mat-icon {
            color: $filters__button-collapse--mat-icon--color;
            border-color: $filters__button-collapse--mat-icon--border-color;
        }

        &:not(.mat-mdc-outlined-button) {
            color: $default-font-color;
        }
    }

    // TODO remove :not selector :(
    button, button:not(.mat-mdc-outlined-button) {
        @extend %button--accent;

        &[disabled] {
            color: $filters__button--disabled--color;
        }
    }
    // ---------- datepicker
     button:not(.mat-mdc-outlined-button) svg.mat-datepicker-toggle-default-icon > path:first-child { // bad default heritage
        fill: currentColor;
    }
    // ---------- rechercher
    .filters-field-launchSearch {
        margin-right: 20px;
    }

    .groups-list__filters__button {
        @include button("primary", $bordered-gradient: false);
    }

    // ----- SEARCH FILTERS

    app-search-filters {

        form {

            .search-filters__container {

                .search-filters__chapter-field {
                    @if ($search-filters__chapter-field--hidden == true) {
                        @include visually-hidden;
                    }
                }

                .search-filters__bookmark-checkbox,
                .search-filters__autocorrect-checkbox {

                    .mdc-form-field {

                        .mdc-checkbox {

                            .mdc-checkbox__background {
                                background-color: $mat-form-field-checkbox-background-color !important; // Override Material style
                                border: #{$mat-form-field-checkbox-border} !important; // Override Material style
                                border-radius: $mat-form-field-checkbox-border-radius;

                                .mdc-checkbox__checkmark {

                                    path {
                                        stroke: $mat-form-field-checkbox-checkmark-stroke-color;
                                    }
                                }
                            }
                        }

                        .mdc-label {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}