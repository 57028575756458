app-read-pdf {
    height: 100%;

    .read-pdf__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include g-2;
        height: 100%;

        .read-pdf__icon {
            flex: auto;
            width: auto;
            min-width: 100px;
            max-width: 250px;
            height: auto;
            min-height: 100px;
            max-height: 250px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .read-pdf__button {
            @include primary-button;
        }
    }
}