// --------------------------------------------------
// STYLE
// --------------------------------------------------

a {
    color: $link--color;
    text-decoration: $link--text-decoration;
}

// --------------------------------------------------
// LAYOUT
// --------------------------------------------------

fuse-app-lessons {

    .page-layout {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    router-outlet + *:not(router-outlet) { // Target dynamically loaded components
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0;
    }
}