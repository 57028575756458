app-followed-tabs {
    flex: 1 0 auto !important;
    min-width: min-content; // Allow horizontal scrolling when the screen is too narrow
    padding: 1.5rem 3rem;
    background-color: $followed-tabs--background-color;

    .followed-tabs {
        overflow: hidden;
        flex: none !important;
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, auto);
        align-content: start;
        align-items: center;

        @include media-breakpoint(gt-sm) {
            grid-template-columns: 1fr auto;
            grid-template-rows: repeat(4, auto);
        }

        .followed-tabs__nav {
            grid-column: 1 /span 1;
            grid-row: 1 / span 1;
            margin-bottom: 3rem;
            border: unset;

            .followed-tabs__nav-button {}

            .mat-mdc-tab-link-container {

                .mat-mdc-tab-list {

                    .mat-mdc-tab-links {
                        justify-content: unset;
                        gap: 1rem;

                        .followed-tabs__nav-button {
                            @include button-line-height;
                            flex: none;
                            height: unset;
                            padding: 1rem 0.5rem;
                            opacity: unset;
                            color: $followed-tabs__nav-button--color;
                            font-family: $followed-tabs__nav-button--font-family;
                            font-size: 1.6rem;
                            font-weight: 500;

                            .mdc-tab__content {

                                .mdc-tab__text-label {
                                    color: inherit;
                                }
                            }

                            .mdc-tab-indicator {

                                .mdc-tab-indicator__content--underline {
                                    border-top: 4px solid $followed-tabs__underline--border-top-color;
                                }
                            }

                            &.mdc-tab--active {
                                font-weight: 800;
                            }
                        }
                    }
                }
            }
        }

        .followed-tabs__panel {
            display: contents;
        }
    }
}

// --------------------------------------------------
// SUBCOMPONENT LAYOUT
// --------------------------------------------------

app-generic-assignments-list,
app-self-assignments-list {
    display: contents !important;

    app-followed-list {
        display: contents;

        .followed-list {
            display: contents !important;

            .followed-list__content {
                display: contents !important;

                .followed-list__filter-button {
                    grid-column: 1 /span 1;
                    grid-row: 2 / span 1;
                    margin-bottom: 3rem;

                    @include media-breakpoint(gt-sm) {
                        grid-column: 2 /span 1;
                        grid-row: 1 / span 1;
                    }
                }

                app-followed-filter {
                    grid-column: 1 /span 1;
                    grid-row: 3 / span 1;
                    margin-bottom: 3rem;

                    @include media-breakpoint(gt-sm) {
                        grid-column: 1 /span 2;
                        grid-row: 2 / span 1;
                    }
                }

                .followed-list__table-wrapper {
                    grid-column: 1 /span 1;
                    grid-row: 4 / span 1;
                    overflow: hidden;

                    @include media-breakpoint(gt-sm) {
                        grid-column: 1 /span 2;
                        grid-row: 3 / span 1;
                    }
                }

                .followed-list__paginator {
                    grid-column: 1 /span 1;
                    grid-row: 5 / span 1;

                    @include media-breakpoint(gt-sm) {
                        grid-column: 1 /span 2;
                        grid-row: 4 / span 1;
                    }
                }
            }
        }
    }
}