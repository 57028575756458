// parent @import "../pages/app-lesson-meta-form-as-dialog";
app-lesson-meta-form {
    flex: 1;
    display: flex;
    padding: 40px;

    form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 50px;

        .wrapper-column {
            display: flex;
            flex-direction: column;
            flex: 1 0 50%;
            gap: 10px;

            > .wrapper-form-field {
                flex: none;

                &.lesson-meta-form__name,
                &.lesson-meta-form__langue,
                &.lesson-meta-form__difficulty,
                &.lesson-meta-form__criteria,
                &.lesson-meta-form__usages {}

                .mat-mdc-form-field {
                    width: 100%;
                }

                &.lesson-meta-form__usages {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    > .mat-mdc-form-field-label {
                        display: block;
                        inset: 0;
                        position: relative;
                    }

                    > .wrapper-form-field {
                        display: flex;
                        row-gap: 20px;
                        flex-direction: column;
                    }

                    .mat-mdc-slide-toggle {
                        display: flex;
                        height: auto;
                        padding: 14px 20px;
                        border-radius: var(--radius-s, 6px);
                        border: 1px solid var(--gris-de-contour, #CBD1DB);
                        background: var(--blanc, #FFF);

                        .mdc-form-field {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: row-reverse;
                        }
                    }
                }
            }
        }
    }

    .mat-mdc-tab-group {
        flex: none;
        height: auto;
    }

    .mat-mdc-tab-header {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-mdc-tab-label-container {
            padding: 12px 20px;
            background-color: #323a49;
            border-radius: 5px 5px 0 0;
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-mdc-tab-labels {
                gap: 10px;
                counter-reset: count;
            }

        }

    }


}