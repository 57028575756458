/*
* DOSSIER BASE
*
*   Le dossier base/ contient ce que nous pourrions appeler le code standard (boilerplate) du projet.
*   On pourrait y trouver par exemple le fichier de reset, quelques règles typographiques,
*   et probablement une feuille de style définissant quelques styles standard pour les éléments HTML
*   les plus employés (que j’ai l’habitude d’appeler _base.scss).
 */

@import "base"; // activities css
// @import "helpers"; // chez fuse
// @import "normalize"; // chez fuse
// @import "reset"; // chez fuse
// @import "typography";
