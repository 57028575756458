app-lesson-editor-panel {
    padding: 25px;
    flex: 1;
    gap: 25px;
    display: flex;
    flex-direction: column;

    // app-action-bar
    // app-grid

    app-grid {

        .empty-card {
            border-radius: 12px;
            border: 1px solid var(--noir-5, rgba(0, 0, 0, 0.05));
            background: var(--noir-5, rgba(0, 0, 0, 0.05));
            box-shadow: 0 4px 12px -3px rgba(36, 38, 55, 0.05) inset;
        }
    }

}