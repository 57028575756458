mat-tooltip-component {

    .mdc-tooltip {

        .mdc-tooltip__surface {
            padding: 0.6rem 0.9rem;
            color: map_get($mat-fusedark, contrast, 500);
            background-color: map_get($mat-fusedark, 500);
            font-size: 1.6rem;
            line-height: 1.2;
            letter-spacing: normal;
        }
    }
}