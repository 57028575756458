@use '@angular/material' as mat;
info-graph, app-graph-details-modal {
    position: absolute;
    top: 0;
    left: 0;
    #tooltip{
        position: absolute;
        font-size: 14px;
        width: 280px;
        background: #fff;
        z-index: 9999;

        .mat-mdc-card-header .mat-mdc-card-title {
            font-size: 18px;
        }

        &:after {
            content: '';
            position: absolute;
            margin-left: -8px;
            width: 0; height: 0;
        }


        &.arrow-on-top {
            transform: translate(-50%, 20px);
            &:after {
                top: -9px;
                left: 50%;
                border-bottom: 8px solid mat.get-color-from-palette($primary);
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
        &.arrow-on-top.arrow-on-right {
            transform: translate(calc(-100% + 8px), 20px);
            &:after {
                top: -8px;
                left: calc(100% - 8px);
                border-bottom: 8px solid mat.get-color-from-palette($primary);
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
        &.arrow-on-top.arrow-on-left {
            transform:translate(-10px, 20px);
            &:after {
                top: -8px;
                left: 10px;
                border-bottom: 8px solid mat.get-color-from-palette($primary);;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
        &.arrow-on-bottom {
            transform: translate(-50%, calc(-100% - 20px));
            &:after {
                left: 50%;
                border-top: 8px solid mat.get-color-from-palette($primary);;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
        &.arrow-on-bottom.arrow-on-left {
            transform: translate(20px, calc(-100% + 20px));
            &:after {
                bottom: 11px;
                left: -8px;
                border-right: 8px solid mat.get-color-from-palette($primary);
                border-bottom: 8px solid transparent;
                border-top: 8px solid transparent;
            }
        }
        &.arrow-on-bottom.arrow-on-right {
            transform: translate(calc(-100% - 20px), calc(-100% + 20px));
            &:after {
                left: initial;
                top: initial;
                right: -16px;
                bottom: 10px;
                border-left: 8px solid mat.get-color-from-palette($primary);
                border-bottom: 8px solid transparent;
                border-top: 8px solid transparent;
            }
        }




        .tooltips{
            position: relative;
            border: 2px solid $dialog__title--background-color;

            &.homework{
                border: 2px solid #24B7C7;
                &:after {
                    border-bottom: 8px solid #24B7C7;
                }
                mat-card-header {
                    background-color: #24B7C7;
                }
            }
            &.assessment{
                border: 2px solid #006D86;
                &:after {
                    border-bottom: 8px solid #006D86;
                }
                mat-card-header {
                    background-color: #006D86;
                }
            }

            .mat-mdc-card{
                padding: 0;
            }


            mat-card-header {
                background-color: mat.get-color-from-palette($primary);
                color: white;
                display: flex;
                place-content: center flex-start;
                align-items: center;
                min-height: 52px;
                padding: 0 10px;

                .pastille {
                    order: 1;
                    display: flex;
                    background-color: white;
                    place-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;

                    // Todo add setting for change color in icon
                    .mat-icon {
                        path {
                            fill: mat.get-color-from-palette($primary);
                        }
                        &.homework {
                            path {
                                fill: #24B7C7;
                            }
                        }
                        &.assessment {
                            path {
                                fill: #006D86;
                            }
                        }
                    }
                }
                .mat-mdc-card-header-text {
                    order: 2;

                    .mat-mdc-card-title{
                        margin-bottom: 0;
                    }
                }


            }
            mat-card-content {
                padding: 10px;
            }
        }
    }

}


app-graph-assignation {
    h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        text-align: right;
    }

    label {
        font-size: 18px;
    }

    .box-average{
        padding: 12px 12px 0 12px;

        label {
            font-size: 16px;
        }

        img{
            &.avatar{
                width: 70px;
                height: 70px;
                background: grey;
            }
        }
        .info-average {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            padding: 6px 12px;
            border: 2px solid #91BE46;
            label:first-child{
                color: #91BE46;
            }
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-graph-selector nav .mat-mdc-tab-list .mat-mdc-tab-links button span {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $dialog__link--color !important; // Override Material style
}
app-multi-graph-view {
    app-shared-filters mat-toolbar mat-toolbar-row {
        padding-top: 20px !important; // Désolé mais j'ai pas mieux
    }

    app-shared-filters mat-form-field.mat-mdc-form-field-disabled {
        .mat-mdc-form-field-label,
        .mat-mdc-form-field-label > span {
            color: $dialog__link--color !important;
        }
    }

    app-shared-filters mat-datepicker-toggle button svg.mat-datepicker-toggle-default-icon > path:first-child {
        fill: white !important; // Désolé mais j'ai pas mieux
    }
}


app-graph-details-modal #tooltip {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
}

app-graph-details-modal #tooltip,
app-graph-details-modal #tooltip .tooltips {
    border-radius: 5px 5px 5px 5px;
}

app-graph-details-modal #tooltip > div > mat-card .mat-mdc-card-header {
    background: $dialog__title--background-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
}

app-graph-details-modal #tooltip.arrow-on-top:after {
    top: -9px;
    left: 50%;
    border-bottom: 10px solid $dialog__title--background-color;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

app-single-graph-view .empty-block,
app-multi-graph-view .empty-block{
    background-color: #E8EDF6;
    border-radius: 5px;
    padding: 20px;
    margin: 20px;
    text-align: center;
}