@use '@angular/material' as mat;

// || STRUCTURE CSS DOM
//
//  APP-CORPUS-ROOT
//      content.inner-scroll
//
//          APP-CORPUS-DISPLAY
//              div#file-manager
//                  .page-layout.simple.right-sidebar.inner-scroll
//
//                      .center
//                          .content
//                              FUSE-CORPUS-FILE-LIST
//                                  .page-layout.inner-scroll
//                                      MAT-TOOLBAR
//                                      .content
//                                          TABLE
//                                          MAT-PAGINATOR
//
//                      FUSE-SIDEBAR
//                          div
//                              FUSE-CORPUS-DETAILS-SIDENAV
//                                  .content
//                                  .header
//                                      .title
//                                  .file-details
//                                      .preview.file-icon
//                                          div
//                                              img
//                                      .file-details__actions
//                                          button.mat-mdc-mini-fab.mat-mdc-button-base.mat-accent
//                                          button.mat-mdc-mini-fab.mat-mdc-button-base.mat-accent
//                                          button.mat-mdc-mini-fab.mat-mdc-button-base.mat-accent
//                                          button.mat-mdc-mini-fab.mat-mdc-button-base.mat-accent

/*
.center
 */

app-corpus-root .center {
    > .content { // TODO rmc mat-white-bg
        padding-right: 0 !important; // stuck to the right edge
        padding-top: 0;

        fuse-corpus-file-list {
            max-height: 100%;

            mat-toolbar.filters {
                margin-right: 0; // stuck to the right edge
            }

            .create-corpus-helper.empty-corpus {
                background-color: $corpus__helper--empty--background-color;
                color: $corpus__helper--empty--color;
            }

            .add-resource-button {
                @extend %button--wide;
                margin-bottom: 16px;
            }
        }
    }
}

// FUSE-CORPUS-FILE-LIST
//      TABLE
//          THEAD
//          TBODY
//              TR
//                  TD
//          TFOOT

fuse-corpus-file-list {

    table.mat-mdc-table {

        tr.mat-mdc-row {

            &:hover,
            &.selected {
                color: $corpus__table__row--hover--color;

                td.mat-mdc-cell,
                td.mat-mdc-footer-cell {
                    color: inherit;

                    .mat-icon {
                        color: $corpus__table__row--mat-icon--color;
                    }
                }
            }
        }
    }
}

fuse-corpus-file-list table.mat-mdc-table tr.mat-mdc-row.selected {
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $corpus__table__row--selected--background-color;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        right: -8px;
        width: 15px;
        height: 100%;
        background-color: $corpus__table__row--selected-extension-line--background-color;
    }

    td {
        &:last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

fuse-corpus-file-list table.mat-mdc-table tr td {

    &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        .mat-icon {
            display: inline-block;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }

    &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

app-corpus-root {


    .page-layout.simple.left-sidebar > .sidebar.locked-open,
    .page-layout.simple.right-sidebar > .sidebar.locked-open {
        background-color: $corpus__details-sidebar--background-color;
        color: $corpus__details-sidebar--color;
    }

    fuse-corpus-details-sidenav .content {

        .header {
            flex: none;
        }

        button.mat-mdc-mini-fab {
            @extend %button--square-icon;
        }

        .mat-icon {
            font-size: 33px;
            width: 33px;
            height: 33px;
            min-width: 33px;
            min-height: 33px;
            line-height: 33px;

            padding: 2px;
            border-radius: 5px;
            background-color: #{$accent-c};
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

            svg path {
                background-color: transparent;
            }
        }
    }

    fuse-corpus-details-sidenav .preview {
        border: none;

        .mat-icon {
            flex: none;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }

    fuse-corpus-details-sidenav .groups-and-workGroups-list::after{
        content: ', ';
    }
    fuse-corpus-details-sidenav .groups-and-workGroups-list:last-child::after{
        content:''
    }
}