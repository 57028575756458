app-neo {
    flex: 1;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    background-color: white;
    border: 4px solid $accent-c;
    border-radius: 10px;
    padding: 20px;


    .mat-icon {
        width: inherit;
        height: inherit;
        flex: none;
        margin-block: 1rem;
        color: var(--accent);
        svg {
            width: 5rem;
            height: 5rem;
            min-width: inherit;
            min-height: inherit;
        }
    }
    h3 {
        margin: 0;
        text-align: center;
        b {
            color: $accent-c;
            font-weight: bold; // ? petit soucis d'héritage src/app/themes/shared_theme/base/_typography.scss 138
            font-size: 16px;
        }
    }
    span {
        text-align: center;
    }
    button {
        @include button($icon-on-Right: true);
    }
}