@use '@angular/material' as mat;
@use "sass:map";
@import "map-function";

/**
*   @param Button type : primary, secondary, tertiary, dark
*   @param Button size : s (small), m (medium)
*   @param Option 1: change the default icon's button position (on left by default)
*   @param Option 2: Grow up icon
*   @param Option 3: hide button label
 */
@mixin button (
    $type: primary,
    $size: m,
    $icon-on-Right: false,
    $icon-full: false,
    $text-hide: false,
    $group-button-position: null,
    $flat-button: false,
    $button-bordered: false,
    $link-style: false,
    $fullwidth: false,
    $no-radius: false,
    $bordered-gradient: true,
) {
    @if map.get($button-type, $type) == null {
        @warn 'Button type `#{$type}` not found in the button map.';
        $type: primary;
    }
    $button-map: map.get($button-type, $type);

    // --------------------------------------------------
    // LAYOUT
    // --------------------------------------------------

    position: relative;
    display: flex;
    align-items: center;
    gap: map.get($button-gap, $size);
    width: auto;
    min-width: unset;
    height: map.get($button-height, $size);
    min-height: unset;
    padding: map.get($button-padding, $size);

    // --------------------------------------------------
    // STYLE
    // --------------------------------------------------

    color: map.get($button-map, color);
    background: map.get($button-map, background);
    border-radius: map-deep-get($button-map, border-radius, $size);
    box-shadow: map.get($button-map, box-shadow);
    transition: all 100ms ease-in-out;
    cursor: pointer;

    // --------------------------------------------------
    // FONT
    // --------------------------------------------------

    font-size: map.get($button-map, font-size);
    font-weight: map.get($button-map, font-weight);
    line-height: map.get($button-map, line-height);

    // --------------------------------------------------
    // ICON
    // --------------------------------------------------

    mat-icon.mat-icon { // Increase selector specificity
        flex: none;
        width: map.get($button-icon, $size);
        min-width: 20px;
        height: map.get($button-icon, $size);
        min-height: unset;
        margin: unset;
        line-height: unset;
        color: map.get($button-map, color) !important;

        svg {
            width: 100%;
            min-width: unset;
            height: 100%;
            min-height: unset;
            color: inherit !important;
        }
    }

    @supports selector(:has(+ *)) { // Check browser support for :has() pseudo-class

        .mat-icon + span { // Button label preceded by an icon
            margin-right: 2px;
        }

        span:has(+ .mat-icon) { // Button label followed by an icon
            margin-left: 2px;
        }
    }

    // --------------------------------------------------
    // STATES
    // --------------------------------------------------

    &:hover {
        color: map-deep-get($button-map, hover, color);
        background: map-deep-get($button-map, hover, background);
        box-shadow: map-deep-get($button-map, hover, box-shadow);
    }

    &:active {
        color: map.get($button-map, pressed, color);
        background: map.get($button-map, pressed, background);
        box-shadow: map.get($button-map, pressed, box-shadow);
        transform: translateY(1px);
    }

    &[disabled] {
        color: map.get($button-map, disabled, color);
        background: map.get($button-map, disabled, background);
        box-shadow: map.get($button-map, disabled, box-shadow);

        mat-icon.mat-icon {
            color: map.get($button-map, disabled, color) !important;
        }
    }

    // --------------------------------------------------
    // CONDITIONAL DECLARATIONS
    // --------------------------------------------------

    // Border
    @if map.get($button-map, border) {
        border: map.get($button-map, border);
    } @else if ($button-bordered == true) {
        border: $button-border-width solid; // When border-color is not specified, use border-color default value (button element color)
    } @else if ($bordered-gradient == true) {
        border: none;
        @include gradient-border(
            $border-width: $button-border-width,
            $angle: to bottom,
            $starting-color: map.get($button-map, gradient-border),
            $starting-color-stop: 40%,
            $ending-color: transparent,
            $ending-color-stop: 80%,
        );
    } @else {
        border: none;
    }

    // Icon position
    @if ($icon-on-Right == true) {
        flex-direction: row-reverse;
    }

    // Full size icon
    @if ($icon-full == true) {
        $text-hide: true;
        justify-content: center;
        height: auto;
        aspect-ratio: 1;
        padding: 5px;

        mat-icon.mat-icon {
            width: map.get($button-icon, '#{$size}-full');
            height: map.get($button-icon, '#{$size}-full');
        }
    }

    // Hidden label
    @if ($text-hide == true) {
        flex: none;

        span:not(.mat-mdc-button-persistent-ripple,
                 .mat-mdc-button-ripple,
                 .mat-mdc-button-touch-target,
                 .mat-mdc-focus-indicator) {
            @include visually-hidden;
        }
    }

    // Flat button
    @if ($flat-button == true) {
        box-shadow: none;
    }

    // Full width button
    @if ($fullwidth == true) {
        width: 100% !important;
        max-width: 100%;
        margin: 0 !important;
        justify-content: center;
    }

    // Button without border-radius
    @if ($no-radius == true) {
        border-radius: 0 !important;
    }

    // Button looking like a link
    @if ($link-style == true) {
        padding: 0;
        background: transparent;
        box-shadow: none;

        &::before {
            display: none;
        }

        &:hover,
        &:active {
            background: transparent;
            box-shadow: none;
        }
    }

    // Button inside a button group
    @include is-group-button($group-button-position);
}