mat-dialog-container .mat-mdc-dialog-surface app-bd-generic-alert-modal .mat-mdc-dialog-content {

    .content {
        text-align: center;
    }

    .center {
        margin: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        align-content: center;
    }
}