@use '@angular/material' as mat;

// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

$mat-form-field__mat-error--background-color: #F5E2E2 !default;
$form-field-label-text-transform: uppercase !default;
$mdc-filled-text-field-label-text-size: 1.6rem !default;
$form-field-label-font-weight: bold !default;
$form-field-label-height: 0 !default;
$default-font-style: normal !default;

// --------------------------------------------------
// PLACEHOLDERS
// --------------------------------------------------

// ----- TOP LABEL

%mat-form-field-label-on-top {
    .mat-mdc-form-field {
        --mdc-filled-text-field-label-text-font: #{$form-field-label-font};
        --mdc-filled-text-field-label-text-size: #{$mdc-filled-text-field-label-text-size};
        --mdc-filled-text-field-label-text-weight: #{$form-field-label-font-weight}; // $form-field-label-font-weight: bold;
        --mdc-filled-text-field-label-text-tracking: #{$default-font-style};
        $form-field-label-text-transform: $form-field-label-text-transform;
        --mdc-filled-text-field-label-text-color: #{$form-field-input-color};
        --mdc-filled-text-field-container-color: transparent;
        $mdc-text-field-padding: 0 0 !default;



        .mdc-text-field {
            padding: $mdc-text-field-padding;

            &--filled {
                .mat-mdc-form-field-flex {
                    .mdc-floating-label {
                        font-family: var(--mdc-filled-text-field-label-text-font);
                        font-size: var(--mdc-filled-text-field-label-text-size);
                        font-weight: var(--mdc-filled-text-field-label-text-weight);
                        letter-spacing: var(--mdc-filled-text-field-label-text-tracking);
                        text-transform: $form-field-label-text-transform;
                    }
                }
            }
        }


        .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
        .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
            color: var(--mdc-filled-text-field-label-text-color);
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background-color: var(--mdc-filled-text-field-container-color);
        }

        .mat-mdc-form-field-icon-suffix {
            .mat-mdc-icon-button.mat-mdc-button-base {
                // --mdc-icon-button-state-layer-size: 2.4rem; // Réduire la taille de l'icone ?
                // $mat-mdc-icon-button-padding: 0 !default; // Réduire la taille de l'icone ?
                width: var(--mdc-icon-button-state-layer-size);
                height: var(--mdc-icon-button-state-layer-size);
                // padding: $mat-mdc-icon-button-padding; // Réduire la taille de l'icone ?

                //display: flex; // Réduire la taille de l'icone ?
                //align-items: center; // Réduire la taille de l'icone ?
                //justify-content: center; // Réduire la taille de l'icone ?
            }
            .mat-mdc-icon-button svg, .mat-mdc-icon-button img {
                // --mdc-icon-button-icon-size: 1.6rem; // Réduire la taille de l'icone ?
                width: var(--mdc-icon-button-icon-size);
                height: var(--mdc-icon-button-icon-size);
            }
        }

        .mat-mdc-chip-set {
            &.mdc-evolution-chip-set {
                .mdc-evolution-chip-set__chips {
                    margin-left: 0;
                }

            }
        }

    }

}

mat-form-field {
    // &.mat-mdc-form-field-type-mat-input
    // &.mat-form-field-appearance-fill
    // &.mat-primary THEME
    // STATES : ng-untouched ng-pristine ng-invalid
    // &.mat-mdc-form-field-label-always-float LABEL Option : always-float, auto, never
    // &.mat-focused
    &.mat-mdc-form-field { // base du champ par défaut
        min-width: 150px;

        &.mat-mdc-form-field-type-mat-input { // pour l'instant pas de propriétés liées à ce type
        }
        &.mat-form-field-appearance-fill { // ou &.mat-form-field-appearance-outline

        }

        div.mat-mdc-text-field-wrapper { // Positionne et anime le label
            &.mdc-text-field { // base du champ par défaut

            }
            &.mdc-text-field--filled { // Style ou &.mdc-text-field--outlined

            }

            div.mat-mdc-form-field-flex {
                div.mat-mdc-form-field-infix {

                }
            }

        }
    }
}


.mat-mdc-chip-set {
    &.mdc-evolution-chip-set {
        display: flex;
        align-items: center;

        .mdc-evolution-chip-set__chips {
            margin-left: 0;
            align-items: center;
        }

    }
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

// ----- MAT-SELECT-PANEL

div.mat-mdc-select-panel.mat-primary .mat-mdc-select-content,
div.mat-mdc-select-panel.mat-primary .mat-mdc-select-panel-done-animating {
    background-color: $form-field-select-panel-background-color;
    color: white;
}

// ----- MAT-SELECT-ARROW

.mat-mdc-form-field {

    &.mat-form-field-appearance-fill .mat-mdc-form-field-label {
        top: 1.28125em;
    }

    .mat-mdc-select-arrow {
        width: 24px;
        height: 24px;
        margin: 0 4px;
        border: none;
        background-color: $mat-form-field__select-arrow--color;
        -webkit-mask-image: icon('chevron-up.svg');
        mask-image: icon('chevron-up.svg');

    }

    .mat-mdc-select.mat-focused .mat-mdc-select-arrow {
        background-color: mat.get-color-from-palette($accent);
    }

    .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
        background-color: mat.get-color-from-palette($warn);
    }
}

// ----- MAT-OPTION

.mat-option:not(.mat-option-disabled) {

    &.mat-active {
        outline: 2px solid black;
        outline-offset: -2px;
    }

    &.mat-mdc-selected {
        color: $default-font-color !important;
    }
}

// ----- MAT-CHECKBOX

mat-checkbox.mat-mdc-checkbox.mat-accent {

    label.mdc-form-field {

        .mdc-checkbox { // déco
            
            // <input
            .mdc-checkbox__background {
                border: #{$mat-form-field-checkbox-border};
                border-radius: $mat-form-field-checkbox-border-radius;
                background-color: $mat-form-field-checkbox-background-color;

                svg.mdc-checkbox__checkmark path {
                    stroke: $mat-form-field-checkbox-checkmark-stroke-color !important;
                }
            }
        }
    }
}

// ----- MAT-ERROR
.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-subscript-wrapper {
    display: contents; // Hide this Material element but not its children
}

#login, #register, #forgot-password-form,
.mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-subscript-wrapper {
    .mat-error {
        margin-top: 0.6rem;
        padding: 0.6rem 1.2rem;
        background-color: $mat-form-field__mat-error--background-color;
        border-radius: 6px;
        font-size: 1.4rem;
        font-weight: 500;
    }
}