// TODO mettre ça ailleurs, mais pour l'instant c'est la seule façon de faire sans devoir créer des variables dans toutes les instances
app-multi-graph-view {
    //background-color: #E8EDF6; // dans les autres pages on a pas suivi les maquettes, ici non plus alors
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-graph-selector nav .mat-mdc-tab-list .mat-mdc-tab-links button span {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #323A49;
}

app-multi-graph-view app-shared-filters mat-toolbar mat-toolbar-row {
    padding-top: 20px !important; // Désolé mais j'ai pas mieux
}

app-multi-graph-view app-shared-filters mat-form-field.mat-mdc-form-field-disabled {
    .mat-mdc-form-field-label,
    .mat-mdc-form-field-label > span {
        color: gray !important;
    }
}

app-multi-graph-view app-shared-filters mat-datepicker-toggle button svg.mat-datepicker-toggle-default-icon > path:first-child {
    fill: white !important; // Désolé mais j'ai pas mieux
}

app-graph-details-modal #tooltip {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
}

app-graph-details-modal #tooltip,
app-graph-details-modal #tooltip .tooltips {
    border-radius: 5px 5px 5px 5px;
}

app-graph-details-modal #tooltip > div > mat-card .mat-mdc-card-header {
    background: #323A49;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
}

app-graph-details-modal #tooltip.arrow-on-top:after {
    top: -9px;
    left: 50%;
    border-bottom: 10px solid #323a49;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

app-single-graph-view .empty-block,
app-multi-graph-view .empty-block{
    background-color: #E8EDF6;
    border-radius: 5px;
    padding: 20px;
    margin: 20px;
    text-align: center;
}