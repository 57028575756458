// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

/**
 * For smaller components, there is the components/ folder. While layout/ is macro (defining the global wireframe), components/ is more focused on widgets. It contains all kind of specific modules like a slider, a loader, a widget, and basically anything along those lines. There are usually a lot of files in components/ since the whole site/application should be mostly composed of tiny modules.
*/

@import "components/bd-card";
@import "components/bd-content-editor";
@import "components/bd-data-editor-modal";
@import "components/bd-generic-alert-modal";
@import "components/bd-list";
@import "components/bdtool-assets-menu";
@import "components/bdtool-close-bd-modal";
@import "components/bdtool-comic-boxes-listing-actions";
@import "components/bdtool-edit-comic-box";
@import "components/bdtool-edit-comic-box-actions";
@import "components/bdtool-new-comic-box-listing-item";
@import "components/bdtool-pagination";
@import "components/bdtool-root";