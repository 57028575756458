@use '@angular/material' as mat;

// --------------------------------------------------
// GENERIC DIALOG
// --------------------------------------------------

mat-dialog-container {

    .mat-mdc-dialog-surface {
        overflow: hidden;
        color: map_get($mat-black, 500);
        background-color: map_get($mat-white, 500);
        border: none;

        > *:not(router-outlet) { // Direct children
            position: relative;
            flex: 1 1 0%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
        }

        .mat-mdc-dialog-title,
        .activities-dialog-title {
            flex: none;
            min-height: 44px;
            padding: 10px 14px 10px 24px;
            color: $dialog__title--color;
            background-color: $dialog__title--background-color;

            h1,
            h2,
            h3,
            .h1,
            .h2,
            .h3 {
                font-weight: bold;

                + button.close {
                    margin-left: auto;
                }
            }

            button.close {
                @include button("primary", $icon-full: true );
            }
        }

        .mat-mdc-dialog-content {
            color: inherit;

            div {

                p {

                    a {
                        color: $dialog__link--color;
                        text-decoration: $dialog__link--text-decoration;
                    }
                }
            }

            button {

                &.submit-button {

                    &[disabled] {
                        color: $dialog__submit-button--disabled--color;
                    }
                }
            }
        }

        .mat-mdc-dialog-actions {

            button {
                @extend %button--base;
                @extend %button--accent;
                padding: 8px 16px;
                line-height: 1.4;
            }
        }
    }
}

// --------------------------------------------------
// EDIT LESSON DIALOG
// --------------------------------------------------

app-edit-lesson-dialog {
    .mat-mdc-form-field-infix mat-select-value {
        min-height: 44px;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #000000;
    }
}

// --------------------------------------------------
// ACTIVITY LIST DIALOG
// --------------------------------------------------

.activities-list-dialog .mat-mdc-dialog-surface {
    height: 95%;
    margin-top: 1%;
    padding: 0;

    .activities-dialog-title button {
        height: 24px;
        margin-left: auto;
        width: 24px;
    }

    .mat-mdc-dialog-content {
        flex-direction: row;
    }

    .multi-content {
        flex: 1;

        .mat-mdc-card.activities-card {
            .mat-mdc-card-header {
                display: none;
            }

            .mat-mdc-card-content {
                flex: 1;
                height: 100%;
            }
        }
    }

    .preview-wrapper, .next-wrapper {
        min-width: 60px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        flex: none !important;

        .preview, .next {
            padding: 0;
            margin: 0;
            width: 32px;
            height: 32px;
            background-color: mat.get-color-from-palette($accent);

            &[disabled] {
                display: none;
            }
        }
    }

}

.activities-list-dialog-preview {
    mat-card-footer {
        display: none !important; // in preview mode we not show action button
    }
}

// --------------------------------------------------
// ?
// --------------------------------------------------

.fullsizeImage {
    .mat-mdc-dialog-surface {
        width: 80% !important;
        margin: auto !important;
        height: auto;
    }

    .mat-mdc-dialog-content {
        padding: 0 15px 15px 15px !important;
    }

    .consigne-img {
        width: 100%;

        .is-picture {
            height: 100% !important;
        }
    }
}

// --------------------------------------------------
// BUTTON LIST DIALOG
// --------------------------------------------------

app-button-list-dialog {

    .mat-mdc-dialog-title {
        margin: 0px !important; // il y a un margin-bottom + padding dans le mat-dialog-content, il faut en retirer un des deux
    }

    .mat-mdc-dialog-content {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        gap: 10px;

        button {
            margin-left: 0 !important; // tout les boutons sauf le premier on un margin-left ?!
        }
    }
}

// --------------------------------------------------
// ?
// --------------------------------------------------

%drop-zone-style {
    border-radius: 0 !important;
    border: 2px dashed $default-font-color !important;
    margin: 15px 0 !important;
    padding: 5px 10px;
    min-height: 120px;
}

%drop-content-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ngx-file-drop__drop-zone-label {
        @extend %corpus-text-style;
        color: $default-font-color;
    }

    .btn {
        @extend %button--accent;
        padding: 10px;
        margin-top: 10px;
    }
}

// --------------------------------------------------
// ?
// --------------------------------------------------

.corpus-upload {
    %corpus-text-style {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
    }

    .text-center {
        text-align: left;

        span {
            @extend %corpus-text-style;
            color: $default-font-color;
        }

        em {
            @extend %corpus-text-style;
            font-weight: 400;
            color: $default-font-color;
        }

        .ngx-file-drop__drop-zone {
            @extend %drop-zone-style;
        }

        .ngx-file-drop__content {
            @extend %drop-content-style
        }
    }

    .content-url-import {
        @extend %corpus-text-style;
        color: $default-font-color;

        mat-form-field {
            justify-content: center;
            display: flex;
        }

        .mat-mdc-form-field-wrapper {
            width: 60%;

            input {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: $default-font-color;
            }
        }
    }
}

// --------------------------------------------------
// RESOURCE UPLOAD DIALOG
// --------------------------------------------------

app-resource-upload-modal-step2 {
    .ngx-file-drop__drop-zone {
        @extend %drop-zone-style;
    }

    .ngx-file-drop__content {
        @extend %drop-content-style
    }

    .gx-file-drop__drop-zone-label {
        @extend %corpus-text-style;
    }

    .mat-mdc-dialog-content .content .content {
        padding: 20px 0 !important;

        :nth-child(3) {
            @extend %corpus-text-style;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 14px;
        }

        :nth-child(4) {
            @extend %corpus-text-style;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }

        :nth-child(5) {
            @extend %corpus-text-style;
            font-weight: 400;
        }
    }

    .mat-mdc-dialog-content fieldset legend, .mat-mdc-dialog-content div p {
        @extend %corpus-text-style;
        font-weight: 400;
    }

    .mat-mdc-form-field-label span {
        @extend %corpus-text-style;
        font-size: 12px;
        text-transform: uppercase;
        color: $default-font-color;
    }

    input {
        @extend %corpus-text-style;
        font-weight: 400;
    }
}

// --------------------------------------------------
// FEEDBACK DIALOG
// --------------------------------------------------

app-feedback {

    .feedback {

        .feedback__header {

            .feedback__title {
                font-size: 1.6rem;
            }
        }

        .feedback__actions {

            .feedback__next-button {

                mat-icon {
                    display: none;
                }
            }
        }
    }
}