app-compass-resource {
    border: 1px solid #FFF;
    background: #D8DCE4;

    @include genericInnerPageLayout;
    @extend %compass-typography;

.app-compass-card__footer {
    display: flex;
    flex-direction: row;
    gap: 30px;
    .app-compass-card__footer__media {
        // @include button($type: tertiary, $size: m);
        display: flex;
        flex-direction: row;
        width: 220px;
        padding: 15px 10px;
        align-items: center;
        gap: 15px;

        border-radius: 12px;
        border: 2px solid currentColor;
        background: #FFF;

        &:hover {
            box-shadow: none;
        }

        mat-icon.mat-icon {
            flex: none;
            width: 62px;
            height: 62px;
            flex-shrink: 0;

            border-radius: 9.394px;
            border: 1.2px solid currentColor;
            background: var(--Blanc, #FFF);
            box-shadow: 0px 2.296px 10.448px 0px currentColor;
            color: inherit !important;
        }


    }

}

}