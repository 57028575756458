app-followed-list {
    $table-border-radius: 12px;
    $table-body-padding: 1.5rem;
    $table-cell-padding: 1rem;

    .followed-list {

        .followed-list__header {}

        .followed-list__content {
            color: $followed-list__content--color;

            .followed-list__banner-info {
                display: none;
            }

            .followed-list__filter-button {
                @include button($type: $followed-list__filter-button--type);
                @include button-line-height;
                width: max-content !important;
                font-size: 1.4rem;
            }

            app-followed-filter {

                .followed-filter {

                    .followed-filter__toolbar {
                        padding: 2.5rem 2.5rem 1rem 2.5rem;
                        margin: unset;
                        background-color: $followed-filter__toolbar--background-color;
                        border: unset;
                        border-radius: 8px;
                        box-shadow: unset;

                        .followed-filter__toolbar-row {
                            padding: unset;
                        }
                    }
                }
            }

            .followed-list__table-wrapper {
                min-width: min-content; // Allow horizontal scrolling when the screen is too narrow

                .followed-list__table {
                    padding: unset;
                    border-spacing: unset !important;
                    background-color: $followed-list__table--background-color;
                    border: unset;
                    border-top-left-radius: $table-border-radius;
                    border-top-right-radius: $table-border-radius;
                    font-weight: 700;

                    // Table header
                    thead {
                        color: $default-font-contrast-color;
                        background-color: $secondary-c;
                        border-top-left-radius: inherit;
                        border-top-right-radius: inherit;
                        border-bottom-right-radius: unset;
                        border-bottom-left-radius: unset;

                        // Table header row
                        .mat-mdc-header-row {
                            height: unset;
                            color: inherit;
                            background-color: unset;
                            border-radius: inherit;
                            cursor: auto;
                            
                            // Table header cell
                            .mat-mdc-header-cell {
                                @include gradient-border(
                                    $border-width: 1px,
                                    $angle: to bottom,
                                    $starting-color: rgba(255, 255, 255, 0.5),
                                    $starting-color-stop: 40%,
                                    $ending-color: transparent,
                                    $ending-color-stop: 80%,
                                );
                                display: table-cell !important; // Always display table header cell (even if the fxHide directive says the opposite)
                                color: inherit;
                                font-weight: 800;
                                padding: $table-cell-padding;
                                border: unset;

                                // First table header cell
                                &:first-child {
                                    padding-left: calc($table-body-padding + $table-cell-padding);
                                    border-top-left-radius: inherit;
                                    border-bottom-left-radius: unset;

                                    &::before {
                                        padding-right: unset; // Remove gradient right border
                                    }
                                }

                                // Last table header cell
                                &:last-child {
                                    padding-right: calc($table-body-padding + $table-cell-padding);
                                    border-top-right-radius: inherit;
                                    border-bottom-right-radius: unset;

                                    &::before {
                                        padding-left: unset; // Remove gradient left border
                                    }
                                }

                                // Table header body cell thats is not first nor last
                                &:not(:first-child, :last-child) {

                                    &::before {
                                        padding-right: unset; // Remove gradient right border
                                        padding-left: unset; // Remove gradient left border
                                    }
                                }

                                // Table header cell containing assignment-related data
                                &.mat-column-assignment_title {

                                    span {
                                        @if ($followed-list__assignment-header--visually-hidden) {
                                            @include visually-hidden;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Table body
                    tbody {
                        background-color: unset;

                        &::before {
                            content: none; // Remove deprecated pseudo element set in shared_theme/shared_core/table.scss
                        }

                        // Table body row
                        .mat-mdc-row {
                            height: unset;
                            background-color: unset;
                            border-radius: unset;
                            box-shadow: none;
                            cursor: auto;

                            // Table body cell
                            .mat-mdc-cell {
                                @include gradient-border(
                                    $border-width: 1px,
                                    $angle: to bottom,
                                    $starting-color: rgba(255, 255, 255, 1),
                                    $starting-color-stop: 40%,
                                    $ending-color: transparent,
                                    $ending-color-stop: 80%,
                                );
                                display: table-cell !important; // Always display table body cell (even if the fxHide directive says the opposite)
                                padding: $table-cell-padding;
                                color: unset;
                                background-color: rgba(255, 255, 255, 0.75);
                                background-clip: padding-box; // Prevent background-color to extend underneath borders
                                border-top: $table-body-padding solid transparent;
                                border-radius: unset;

                                // First table body cell of each row
                                &:first-child {
                                    border-left: $table-body-padding solid transparent;
                                    clip-path: padding-box inset(0 0 0 0 round 8px 0 0 8px);

                                    &::before {
                                        border-top-left-radius: 8px;
                                        border-bottom-left-radius: 8px;
                                        padding-right: unset; // Remove gradient right border
                                    }
                                }

                                // Last table body cell of each row
                                &:last-child {
                                    border-right: $table-body-padding solid transparent;
                                    clip-path: padding-box inset(0 0 0 0 round 0 8px 8px 0);

                                    &::before {
                                        border-top-right-radius: 8px;
                                        border-bottom-right-radius: 8px;
                                        padding-left: unset; // Remove gradient left border
                                    }
                                }

                                // Table body cell thats is not first nor last of each row
                                &:not(:first-child, :last-child) {

                                    &::before {
                                        padding-right: unset; // Remove gradient right border
                                        padding-left: unset; // Remove gradient left border
                                    }
                                }

                                // Table body cell containing class or group-related data
                                &.mat-column-classOrGroups {

                                    .followed-list__group-wrapper {
                                        display: flex;
                                        align-items: center;
                                        gap: 0.5rem;

                                        .followed-list__group-chip {
                                            @include gradient-border(
                                                $border-width: 1px,
                                                $angle: to bottom,
                                                $starting-color: rgba(255, 255, 255, 0.2),
                                                $starting-color-stop: 40%,
                                                $ending-color: transparent,
                                                $ending-color-stop: 80%,
                                            );
                                            display: grid;
                                            grid-template-columns: auto 1fr;
                                            justify-items: stretch;
                                            align-items: center;
                                            gap: 0.5rem;
                                            padding: 0.5rem 1rem;
                                            background-color: $secondary-c;
                                            border-radius: 37px;

                                            .followed-list__group-color {
                                                display: none; // To remove when group color will be fully supported
                                                width: 1.6rem;
                                                height: auto;
                                                aspect-ratio: 1;
                                                border-radius: 50%;
                                            }

                                            .followed-list__group-name {
                                                overflow: hidden;
                                                width: min-content;
                                                max-width: 12ch;
                                                color: $default-font-contrast-color;
                                                font-size: 1.6rem;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                            }
                                        }

                                        .followed-list__group-length {
                                            @include gradient-border(
                                                $border-width: 1px,
                                                $angle: to bottom,
                                                $starting-color: rgba(255, 255, 255, 0.2),
                                                $starting-color-stop: 40%,
                                                $ending-color: transparent,
                                                $ending-color-stop: 80%,
                                            );
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            width: auto;
                                            min-width: 3.2rem;
                                            min-height: 3.2rem;
                                            padding: 0.5rem;
                                            background-color: $followed-list__group-length--background-color;
                                            border-radius: 37px;
                                        }
                                    }
                                }

                                // Table body cell containing concept-related data
                                &.mat-column-concept {

                                    div {
                                        @include gradient-border(
                                            $border-width: 1px,
                                            $angle: to bottom,
                                            $starting-color: rgba(255, 255, 255, 0.2),
                                            $starting-color-stop: 40%,
                                            $ending-color: transparent,
                                            $ending-color-stop: 80%,
                                        );
                                        display: inline-flex;
                                        justify-content: center;
                                        align-items: center;
                                        padding: 0.5rem 1rem;
                                        color: $default-font-contrast-color;
                                        background-color: $followed-list__concept-chip--background-color;
                                        border-radius: 37px;
                                        box-shadow: 0px 4px 4px 0px rgba($secondary-c, 0.1);
                                        font-size: 1.6rem;
                                    }
                                }

                                // Table body cell containing assignment-related data
                                &.mat-column-assignment_title {

                                    button {
                                        display: grid;
                                        grid-template-columns: auto 1fr;
                                        justify-items: stretch;
                                        align-items: center;
                                        gap: 0.5rem;
                                        padding: 0.5rem 1rem;
                                        color: unset;
                                        background-color: unset;
                                        border: unset;
                                        border-radius: 6px;

                                        .mat-icon {
                                            color: $followed-list__assignment-icon--color;
                                        }

                                        span {
                                            display: -webkit-box;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 2; // Limit text to 2 lines
                                            overflow: hidden;
                                            min-width: 20rem;
                                            text-overflow: ellipsis;
                                            font-size: inherit;
                                        }

                                        &:hover {
                                            background-color: $followed-list__assignment-button--background-color--hover;
                                        }
                                    }
                                }

                                // Table body cell containing action buttons
                                &.mat-column-buttons {

                                    .buttons-wrapper {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: stretch;
                                        gap: 0.5rem;

                                        button {
                                            @include button-line-height;
                                            width: unset;
                                            min-width: unset;
                                            height: unset;
                                            min-height: unset;

                                            &.mat-mdc-menu-trigger {
                                                @include button(
                                                    $type: $followed-list__action-button--type,
                                                    $size: $followed-list__action-button--size,
                                                );

                                                .mat-icon {
                                                    color: inherit !important;
                                                    transform: rotate(90deg);

                                                    svg {
                                                        color: inherit !important;
                                                    }
                                                }
                                            }

                                            &:not(.mat-mdc-menu-trigger) {
                                                @include button($type: primary);
                                                min-width: max-content;
                                                font-size: 1.4rem;
                                            }
                                        }
                                    }

                                    &:last-child {

                                        .buttons-wrapper {
                                            justify-content: flex-end;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .followed-list__paginator {
                background-color: $followed-list__paginator--background-color;
                border-bottom-right-radius: $table-border-radius;
                border-bottom-left-radius: $table-border-radius;
                font-size: $default-font-size;
            }
        }
    }
}