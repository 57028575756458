@use '@angular/material' as mat;
@use "sass:map";
@use 'sass:color';
@import "theme";

// --------------------------------------------------
// BUTTONS
// --------------------------------------------------

$button-padding: (
    m: 0.9rem 1.8rem, //var(--gap-m-half, 0.5625rem) var(--gap-m, 1.125rem),
    s: 0.6rem 0.9rem,
    my: 0.9rem,
    sy: 0.6rem,
    mx: 1.8rem,
    sx: 0.9rem,
);

$button-radius: (
    m: 1000px,
    s: 1000px,
);

$button-height: (
    m: null, //var(--gap-xs, 0.375rem),
    s: null,
);

$button-icon: (
    m: 2.4rem,
    s: 1.8rem,
    m-full: 2.4rem,
    s-full: 1.8rem,
);

$button-gap: (
    m: 0.6rem,
    s: 0.6rem,
);

$button-border-width: 2px;

$button-primary: (
    background: $accent-c,
    color: $white-c,
    border: null,
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: none,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    disabled: (
        background: #D5D5D9,
        color: #BAB9C0,
        box-shadow: none,
    ),
);

$button-secondary: (
    background: $white-c,
    color: $accent-c,
    border: $button-border-width solid $accent-c,
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: none,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    disabled: (
        background: #D5D5D9,
        color: #BAB9C0,
        box-shadow: none,
    ),
);

$button-tertiary: (
    background: transparent,
    color: $accent-c,
    border: null,
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: none,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    disabled: (
        background: #D5D5D9,
        color: #BAB9C0,
        box-shadow: none,
    ),
);

$button-quaternary: (
    background: $white-c,
    color: $secondary-c,
    border: $stroke-size-m solid $secondary-c,
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: none,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    disabled: (
        background: #D5D5D9,
        color: #BAB9C0,
        box-shadow: none,
    ),
);

$button-dark: (
    background: null,
    color: null,
    border: null,
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: null,
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: null,
        color: null,
        box-shadow: null,
    ),
    disabled: (
        background: #D5D5D9,
        color: #BAB9C0,
        box-shadow: none,
    ),
);

$button-compass: ( // background: url(/assets/isc_lycee/images/backgrounds/PERSO-05.svg);
    background-color: red,
    border-radius: (
        m: 18px,
        s: 18px,
    ),
    color: var(--white, #fff),
    hover: (
        background-color: darken(purple, 10%),
    ),
    pressed: (
        background-color: darken(purple, 50%),
    ),
    disabled: (
        background: #D1D6DF,
        color: #989CA4,
    ),
    option: (
        flat: true,
    )
);

$button-compass2: ( // background: url(/assets/isc_lycee/images/backgrounds/PERSO-05.svg);
    background: $accent-c,
    border-radius: (
        m: var(--radius-rounded, 62.5rem),
        s: var(--radius-rounded, 62.5rem),
    ),
    color: var(--white, #fff),
    hover: (
        background-color: darken($accent-c, 10%),
    ),
    pressed: (
        background-color: darken($accent-c, 50%),
    ),
    disabled: (
        background: #D1D6DF,
        color: #989CA4,
    )
);

$button-type: (
    primary: $button-primary,
    secondary: $button-secondary,
    tertiary: $button-tertiary,
    quaternary: $button-quaternary,
    dark: $button-dark,
    compass: $button-compass
);