app-assignment-settings-choice { // Temporary style

    .choice-main-container {
        display: flex;
        flex-direction: row;
    }

    .choice-container {
        display: flex;
        flex-direction: column;
        padding: 5px;
        background: red;
        margin: 10px;
    }
}