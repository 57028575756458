// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

$main-toolbar--height: var(--mat-toolbar-standard-height) !default;

// --------------------------------------------------
// STYLE
// --------------------------------------------------

.mat-toolbar-multiple-rows.filters {
    min-height: inherit;
}

body.player toolbar.top-main-toolbar {
    display: none;
}

.top-main-toolbar {

    mat-toolbar {

        app-button-help {
            margin: 0 0.8rem 0 auto;
        }
    }

    .btn__goToLoginPage {
        color: $main-toolbar__button__btn__goToLoginPage--color;
    }

    .user-button {

        &:not(:disabled) { // Increase specificity to override Material style
            color: $main-toolbar__user-button--color;

            mat-icon {
                color: $main-toolbar__user-button--color;
            }
        }

        .account-info {

            .account-labels {
                display: flex;
                align-items: center;
            }
        }
    }
}