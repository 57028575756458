[svgicon="brand"] { // Sidebar

    .brand-background {
        fill: $logo-icon__brand-background--fill;
    }
}

[svgicon="brand_square"] { // Login

    .brand-text-ia {
        color: $logo-icon--square__brand-text-ia--color;
    }

    .brand-background {
        fill: $logo-icon--square__brand-background--fill;
    }
}