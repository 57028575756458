@use '@angular/material' as mat;
@use "sass:map";
@import 'button';

@mixin chips (
    $type: primary,
    $size: m,
) {
    $chip-map: map.get($chip-map, $type);
    $button-map: map.get($button-type, $type);

    // Style
    background: map.get($button-map, background) !important; // Override Material style
    color: map.get($button-map, color) !important;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 16px;
    align-items: center;
    cursor: default;
    height: unset;
    min-height: 32px;
    gap: 10px;
    border: map-get($chip-map, border);

    .mat-icon {
        background-color: transparent;
        opacity: 1;
        color: map.get($button-map, color);

        svg {
            width: inherit;
            height: inherit;
            min-height: inherit;
            min-width: inherit;
            transform: none;
        }
    }

    .mat-mdc-chip-action {        

        .mat-mdc-chip-action-label {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: inherit;
            font-weight: 500;
            text-wrap: unset; // Unset Material style

            a {
                color: inherit;
            }

            .mat-chip-remove {
                background-color: unset; // Unset default browser style
                border: unset; // Unset default browser style

                .mat-icon {
                    width: 1.8rem;
                    height: auto;
                    aspect-ratio: 1;
                }
            }
        }
    }

    &:not(.mat-mdc-chip-option) {

        .mat-mdc-chip-action {
            padding: unset;
        }
    }
}