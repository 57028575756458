.mat-mdc-menu-panel {
    min-height: auto !important;

    .mat-mdc-menu-content {
        display: flex;
        flex-direction: column;
        padding: 0 !important;

        .mat-mdc-menu-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            min-height: 4.5rem;
            margin: 0 !important;
            padding: 0 1.5rem;
            color: $menu-panel__button--color;
            background-color: $menu-panel__button--background-color;
            font: $menu-panel--font;
            border-radius: 0 !important;

            &:hover {
                background: darken(saturate(adjust-hue($accent-c, 3), 1.58), 3.53);
            }

            &.mat-mdc-button-disabled,
            &[disabled] {
                cursor: not-allowed;
                color: rgba(0, 0, 0, 0.26);
                background-color: rgba(0, 0, 0, 0.12);

                .mat-icon {
                    color: rgba(0, 0, 0, 0.26);
                }

                svg path {
                    color: rgba(0, 0, 0, 0.26);
                }

                * {
                    cursor: not-allowed;
                }
            }

            &.menu-button-add-learner-disabled[disabled] {
                background-color: $groups-inline__button-add__disabled--background-color;
                color: $groups-inline__button-add__disabled--color !important;
                cursor: not-allowed;

                mat-icon {
                    color: $groups-inline__button-add__disabled--color !important;
                }
            }

            > .mat-icon {
                display: inline-block;
                order: 2;
                margin-left: 1.5rem;
                margin-right: 0;
                color: $card-action__mat-menu-panel__mat-icon--color;
            }

            > .mat-mdc-menu-item-text {
                order: 1;
                margin-right: auto;
                color: inherit;
                font: inherit;
            }
        }
    }
}