app-action-bar {
    padding: 20px;

    display: flex;
    align-items: center;
    position: relative;



    .editor-panel__lesson-title, .lesson-title-input {
        @include h3-font;
        font-size: 2rem;
        background: unset;
    }
    form {
        flex: 1;
        display: flex;
        input.lesson-title-input {
            flex: 1;
            color: $default-font-color;
            margin-block-start: 0.62em;
            margin-block-end: 0.62em;
            line-height: 1.7rem;
        }
    }

    /** general button is build in shared_theme > button  **/
    .edit-button {
        margin-right: auto;
        margin-left: 13px;
    }
    .undo-button {
        @include is-group-button("start");
        margin-left: auto;
    }
    .redo-button {
        @include is-group-button("end");
    }
}