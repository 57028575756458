// the style apply on all table without exception and mat-calendar use table
// so i replace table by table but not mat-calendar table to not style it
// we can add additionnal add other exception if needed
$table-but-exclude-some-elements: "table:not(.mat-calendar-table)";
#{$table-but-exclude-some-elements} {
    padding: 0 8px;
    width: 100%;
}

#{$table-but-exclude-some-elements} .mat-icon {
    color: $table-icon-stroke-color;
    height: 32px;
    width: 32px;

    [svgIcon="submenu"] {

    }
}

#{$table-but-exclude-some-elements} .mat-mdc-icon-button {
    @extend %button--square-icon;
}

#{$table-but-exclude-some-elements} .mat-mdc-button-base {
    @extend %button--square-icon;
}

#{$table-but-exclude-some-elements} button .mat-icon {
    color: $table__button__icon--color;
}

#{$table-but-exclude-some-elements}.mat-mdc-table thead {
}

#{$table-but-exclude-some-elements}.mat-mdc-table thead th {
}

#{$table-but-exclude-some-elements}.mat-mdc-table thead th.cdk-column-consulted {
}

#{$table-but-exclude-some-elements}.mat-mdc-table tbody {
}

#{$table-but-exclude-some-elements}.mat-mdc-table tbody tr {
}

#{$table-but-exclude-some-elements}.mat-mdc-table tbody tr td.cdk-column-consulted {
}

#{$table-but-exclude-some-elements}.mat-mdc-table thead td.cdk-column-consulted {
}


#{$table-but-exclude-some-elements} th.cdk-column-consulted, td.cdk-column-consulted {
    width: 150px;
    text-align: center;

    .mat-icon {
        color: $table-icon-stroke-inactive-color;
        height: 28px;
        width: 28px;

        &.active {
            color: $table-icon-stroke-color;
        }
    }
}

#{$table-but-exclude-some-elements}.mat-mdc-table {
    border-collapse: separate;
    border-spacing: 0 7px !important;
    background-color: $table--background-color;
}

#{$table-but-exclude-some-elements} th.mat-mdc-header-cell, #{$table-but-exclude-some-elements} td.mat-mdc-cell {
    border: none;
}

#{$table-but-exclude-some-elements} th.mat-mdc-header-cell {
    font-size: $table__thead--font-size;
}

#{$table-but-exclude-some-elements} td.mat-mdc-cell {
    padding: 0 8px;
}

#{$table-but-exclude-some-elements} th,
#{$table-but-exclude-some-elements} th.mat-mdc-header-cell {
    color: $table__thead--font-color;
    border-top: 1px solid rgba(151, 151, 151, 0.5);
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);

    &:first-child {
        border-left: $table__thead--border;
        border-top-left-radius: $table__thead--border-radius;
        border-bottom-left-radius: $table__thead--border-radius;
    }

    &:last-child {
        border-right: 1px solid rgba(151, 151, 151, 0.5);
        border-top-right-radius: $table__thead--border-radius;
        border-bottom-right-radius: $table__thead--border-radius;
    }
}

#{$table-but-exclude-some-elements} td {

    &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

#{$table-but-exclude-some-elements} tr,
#{$table-but-exclude-some-elements} tr.mat-mdc-row {
    height: 56px;
    background-color: $table__tbody-row--background-color;
    border-radius: $table__tbody-row--border-radius;
    box-shadow: $table__tbody-row--box-shadow;

    &.mat-mdc-header-row {
        height: 34px;
    }
}

#{$table-but-exclude-some-elements} thead, #{$table-but-exclude-some-elements} .mat-mdc-header-row {
    background-color: $table__thead--background-color;
    box-shadow: none;

    .mdc-checkbox__background {
        border-color: $table__thead--checkbox-color;
    }

    tr {
        border-radius: $table__thead--border-radius;
    }
}

#{$table-but-exclude-some-elements} tbody {
}

// Vertical Spacer between header and body
#{$table-but-exclude-some-elements} tbody:before {
    content: "";
    display: block;
    height: 2px;
}

#{$table-but-exclude-some-elements} tbody tr:hover {

    &:not(.mat-no-data-row) {
        box-shadow: $table__tbody-row--box-shadow;
        background-color: $table__tbody-row--hover--background-color;
    }

    &.mat-no-data-row {
        cursor: auto;
    }
}

#{$table-but-exclude-some-elements} tbody tr .mat-mdc-cell,
#{$table-but-exclude-some-elements} tbody tr .mat-mdc-footer-cell {
    color: $table__mat-cell--color;
}

#{$table-but-exclude-some-elements} tbody tr:hover {

    &:not(.mat-no-data-row) {

        > .mat-mdc-cell {
            color: $table__mat-cell--SelectedAndHover--color;
            .mat-icon {
                color: $table__mat-cell--SelectedAndHover--color;
            }
        }
    }
}

#{$table-but-exclude-some-elements} tbody tr:hover .nav-link-title {
    color: $table-icon-stroke-color-hover;
}

#{$table-but-exclude-some-elements} tr td.mat-column-actions {
    width: 13%;
}