// ici on commence la fusion des éléments de Modules
// @import "../default/modules/modules";
// ici j'ai copié les propriétés css d'activities default et séparé les imports qu'il contenait
/// @import "modules/activities/_activities-default-copie";

/*
* Les imports contenus dans activities default
*
 */
//// J'ai sortis les couleurs attribuées au status pour les rendres accessibles et indépendantes
//@import 'modules/activities/_activities__answers-status';

@import '../../default/modules/_activities/editors';
// @import '../default/modules/_activities/_activities-card';


//@import "modules/activities/_activities-default-copie";
//@import "modules/activities/_activities-card-default-copie";

@import '../../default/modules/_activities/forms-list';
// @import '../default/modules/_activities/_lessons';
// @import '../default/modules/_activities/_player-components';
// @import 'modules/activities/_player-components-default-copie';
@import '../../default/modules/_activities/feedback-dialog.component';



@import "../../default/modules/account-management";
@import "../../default/modules/achievement";
@import "../../default/modules/agenda";
@import "../../default/modules/assignation";
@import "../../default/modules/authentication";
@import "../../default/modules/basic_page";
@import "../../default/modules/configuration";
@import "../../default/modules/corpus";
@import "../../default/modules/dashboard";
@import "../../default/modules/faq";
@import "../../default/modules/gamecode";
@import "../../default/modules/groups-management";
@import "../../default/modules/home-page";
@import "../../default/modules/ideas-wall";
@import "../../default/modules/licensing";
@import "../../default/modules/mindmap";
@import "../../default/modules/notepad";
@import "../../default/modules/progress-bar-generic";
@import "../../default/modules/project-management";
// @import "../default/modules/_research-sheet"; // pas nécessaire
@import "../../default/modules/timeline";
@import "../../default/modules/dragula";

// ---^^--- DEFAULT ---^^------