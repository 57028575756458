mat-tab-nav-panel {
    flex: 1;
    display: flex;
}

mat-tab-group.mat-primary { // classe .mat-primary par default du composant
        flex: 1;

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    div.mat-mdc-tab-body-wrapper {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        mat-tab-body.mat-mdc-tab-body {
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            div.mat-mdc-tab-body-content {
                display: flex; // s'ajuste au parent
                flex-direction: column;
            }
        }
    }

    .mat-mdc-tab-nav-bar,
    .mat-mdc-tab-header {
        border: none;
    }

    &.mat-mdc-tab-group,
    .mat-mdc-tab-nav-bar {

        .mdc-tab-indicator {                        

            .mdc-tab-indicator__content--underline {
                border-top-color: $tabs__underline--border-top-color;
            }
        }
    }

    .mdc-tab {
        justify-content: center;
        align-items: flex-end;

        .mdc-tab__content {
            padding-bottom: 5px;
        }
    }

   &.mat-mdc-tab-group .mdc-tab {
        background-color: transparent;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #323A49;
    }
}

.mat-mdc-tab-nav-bar.mat-primary {

    .mat-mdc-tab-links {
        justify-content: center;

        .mat-mdc-tab-link {
            flex: none;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #323A49;

            .mdc-tab-indicator {                        

                .mdc-tab-indicator__content--underline {
                    border-top-color: $tabs__underline--border-top-color;
                }
            }
        }
    }
}