bdtool-close-bd-modal {

    .mat-mdc-dialog-title {

        button.close {

            .mat-icon {

                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    background-color: white;
                    mask-image: icon('cross.svg');
                    mask-size: contain;
                    mask-repeat: no-repeat;
                }
            }
        }
    }

    .mat-mdc-dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        min-width: unset !important; // Override default theme style

        h2 {
            text-align: center;
        }

        button {
            margin: unset !important; // Override shared theme style
        }
    }
}