$fake-card--background-color: null !default;
$fake-card--color: null !default;
$card__header--breadcrumb--background-color: null !default;
$card__header--breadcrumb--color: null !default;

app-mindmap-list {
    & > div.page-layout.simple {
        flex-basis: inherit; // remove auto
    }

    .mindmap-card-list {
        flex: 1;

        > .content {
            @include mx-2;
            @include my-2;
            @include grid(
                $sm-col: 2,
                $sm-row: 1,
                $lg-col: 2,
                $lg-row: 1,
                $xl-col: 3,
                $xl-row: 1,
                $gap: 22
            );
        }

        .card-split-media-info .mat-mdc-card.mat-mdc-card {
            padding-bottom: 60px; // 60px is the height of the footer
        }

        .fake-card {
            @include fake-card;
        }

        .fake-card .mat-mdc-card {
            background-color: $fake-card--background-color;
            color: $fake-card--color;
        }
    }
}

app-mindmap-card {

    .mat-mdc-card {

        .mat-mdc-card-header {
            background-color: $card__header--breadcrumb--background-color !important;

            .mat-mdc-card-title * {
                color: $card__header--breadcrumb--color !important;
                text-transform: uppercase;
            }

            button {

                .mat-icon {
                    color: inherit;
                }
            }
        }
    }
}