/*
*
*   From corpus-display.component
*   TODO merge this css component/default
 */

 app-corpus-root {

    button.file-uploader {
        position: absolute;
        z-index: 1000;
        bottom: 15px;
        right: 15px;
    }
    table {
        td {
            flex-direction: column;
        }
        th, td {
            &.mat-column-icon {
                text-align: center;
                .mat-icon {
                    vertical-align: middle;
                }
            }
        }
    }

    app-corpus-display {
        flex: 1 1 0%;

        .create-corpus-helper {
            color: $default-font-contrast-color;
            font-size: 16px;
            padding-left: 16px;
            &.empty-corpus {
                background-color: $accent-c;
                text-align: center;
                padding: 15px 20%;
                font-weight: 700;
            }
        }

        #file-manager {

            .center {
                overflow: hidden;

                > .content {
                    padding: 16px;
                    flex: 1 1 auto;
                    overflow: auto;
                }
            }

            .sidebar {
                width: 250px;
                min-width: 250px; // 320px !important; + 30px de padding
                max-width: 250px; // 320px !important; + 30px de padding
                // change margin en padding pour que sidebar occupe tout sa place pour une couleur de fond uniforme
                padding: 0 15px;

                &.details-sidebar { // ? .right-positioned
                    @include media-breakpoint('gt-md') { // sidebar
                        z-index: 0;
                    }
                }
                .preview {
                    > div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .preview .mat-icon { // ? default ?
                    height: 48px; // mat size 40px
                    width: 48px;
                    cursor: pointer;
                }

                dl { // voir core/_list
                    dt, dd {} // modèles pour themes
                    dt {} // modèles pour themes
                    // modèles pour themes
                    dt {
                        &.learning-time {}
                        &.concepts {}
                        &.chapters {}
                        &.levels {}
                        &.difficulty {}
                        &.skills {}
                        &.created {}
                        &.changed {}
                        &.auteur {}
                        &.source {}
                        &.format {}
                        &.description {}
                        &.indexation {}
                        &.size {}
                        &.author {}
                    }
                }


            }

        }

    }
}


 app-corpus-display-embed {
    flex-direction: column;
}