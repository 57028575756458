.mat-mdc-radio-button {
    &.mat-accent {
    }
}
// Style Black and White
@mixin style-bw-form {
        // Style
        border-radius: 5px;
        border: 1px solid var(--gris-de-contour, #CBD1DB);
        background: var(--blanc, #FFF);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}


// progression design
.progression { // default
    .mat-form-field-wrapper {
        padding-bottom: inherit;
        .mat-form-field-infix {
            width: inherit;
            border: inherit;
        }
    }

    select {
        display: inline-flex;
        padding: 10px 14px;
        align-items: center;
        gap: 14px;
        @include style-bw-form;
    }
}