// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin previewTypology {
    color: $default-font-color;

    strong,
    .strong {
        font-weight: bold;
    }
    span {
        padding-top: 3px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

@mixin previewLayout {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &::before { // overlay color
        display: none;
    }

    > * {
        min-width: unset;
        min-height: unset;
    }

    mat-icon.mat-icon {
        flex: 1;
        aspect-ratio: 1;
        width: unset;
        max-width: 20%;
        min-width: 37px;

        svg {
            width: unset;
            height: unset;
        }
    }

    span {
        flex: 1;
    }

}

@mixin previewCarousel {
    flex: 1;
    flex-direction: row;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    object-fit: unset;
    height: unset;
    width: unset;
    padding: 8px;
    gap: 0 4px;

    &::before { // overlay color
        display: none;
    }

    > * {
        flex: 1;
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 50px;
        min-width: unset !important;
        min-height: unset !important;
        max-width: unset !important;
        max-height: unset !important;
        display: block;
    }

    img {
        position: absolute;
        height: auto;
        aspect-ratio: 16 / 9;
        border-radius: 7px;
        border: 1px solid #E7E7E7;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0.00) 18.9%), rgba(255, 255, 255, 0.80);
        box-shadow: 0px 10px 15px 0px rgba(36, 38, 55, 0.10), 0px 2px 2px 0px rgba(36, 38, 55, 0.05), 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset;
        backdrop-filter: blur(5px);

        &:nth-child(1) {
            z-index: 3;
            transform-origin: top right;
        }

        &:nth-child(2) {
            z-index: 4;
            transform-origin: left bottom;
        }

        &:nth-child(3) {
            z-index: 2;
            transform-origin: top left;
        }

        &:nth-child(4) {
            z-index: 1;
            transform-origin: top left;
        }
    }

    &.imgCounter-2 { // If two images
        img:nth-child(1) {
            width: 60%;
            top: 5%;
            right: 3%;
        }

        img:nth-child(2) {
            width: 60%;
            bottom: 5%;
            left: 3%;
        }
    }

    &.imgCounter-3 { // If three images
        img:nth-child(1) {
            width: 45%;
            top: 5%;
            right: 3%;
        }

        img:nth-child(2) {
            width: 50%;
            bottom: 5%;
            left: 23%;
        }

        img:nth-child(3) {
            width: 35%;
            top: 21%;
            left: 3%;
        }
    }

    &.imgCounter-4 { // If four images
        img:nth-child(1) {
            width: 47%;
            top: 14%;
            right: 3%;
        }

        img:nth-child(2) {
            width: 40%;
            bottom: 5%;
            left: 25%;
        }

        img:nth-child(3) {
            width: 35%;
            top: 24%;
            left: 3%;
        }

        img:nth-child(4) {
            z-index: 1;
            width: 25%;
            top: 5%;
            left: 18%;
        }
    }
}

@mixin previewVitrine {
    flex: 1;
    flex-direction: row;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    object-fit: unset;
    height: unset;
    width: unset;
    padding: 1rem;
    gap: 0 0.4rem;

    &::before { // overlay color
        display: none;
    }

    > * {
        flex: 1;
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 50px;
        min-width: unset !important;
        min-height: unset !important;
        max-width: unset !important;
        max-height: unset !important;
        display: block;
    }

    img {
        flex: 1;
        height: 100%;
        width: 20%;
        object-fit: cover;
        border-radius: 4px;
        border: 1px solid #E7E7E7;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0) 18.9%), rgba(255, 255, 255, 0.8);
        box-shadow: 0px 10px 15px 0px rgba(36, 38, 55, 0.1), 0px 2px 2px 0px rgba(36, 38, 55, 0.05), 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-activity-sample {
    display: flex;
    flex: 3;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    margin: 0 1.5rem;
    position: relative;
    color: $default-font-contrast-color;
    max-height: 60%;

    &:has(.grid-multi-answers) {

    }

    .grid-multi-answers {
        display: grid;
        .activity-sample__short-answer_multi {
            display: flex;
            max-width: 100%;
            padding: 0 10px;
            gap: 10px;
            align-items: center;
            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .activity-sample__preview {
        position: absolute;
        z-index: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #F6F6F7;
        border-top: 1px solid #E8E8E8;
        border-right: 1px solid #E8E8E8;
        border-left: 1px solid #E8E8E8;
        border-radius: inherit;

        &::before { // Overlay color
            content: '';
            position: absolute;
            z-index: 2;
            inset: 0;
            background-color: rgba($black-c, 0.5);
        }

        & > * {
            min-width: 100%;
            min-height: 100%;
            z-index: 1;
        }                   

        &.activity-sample__image-ordering {
            @include previewCarousel;
        }

        &.activity-sample__awareness {
            @include previewVitrine;
        }

        &.activity-sample__fill-blanks,
        &.activity-sample__short-answer,
        &.activity-sample__audio-ordering
        {
            @include previewLayout;
            @include previewTypology;

            mat-icon.mat-icon.full-icon {
                max-width: 50%;
            }

        }



        &.activity-sample__memory {
            position: unset; // Unset .activity-sample__preview element default style
            z-index: unset; // Unset .activity-sample__preview element default style
            flex: 1;
            display: grid;
            grid-template-rows: repeat(2, minmax(0, 1fr));
            grid-auto-flow: column;
            grid-auto-columns: minmax(0, 1fr);
            justify-content: unset; // Unset .activity-sample__preview element default style
            align-items: unset; // Unset .activity-sample__preview element default style
            gap: 0.4rem;
            padding: 1rem;
            object-fit: unset; // Unset .activity-sample__preview element default style

            &::before {
                content: none; // Remove overlay color
            }

            > div {
                z-index: unset; // Unset .activity-sample__preview element default style
                display: flex;
                gap: inherit;
                min-width: unset; // Unset .activity-sample__preview element default style
                max-width: 100%;
                min-height: unset; // Unset .activity-sample__preview element default style
                max-height: 100%;

                img,
                div {
                    flex: 1;
                    border: 1px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
                }

                img {
                    min-width: 0; // Prevent grid blowout
                    object-fit: cover;
                    object-position: center;
                }

                div {
                    background-color: $primary-c;
                }

                &:nth-child(odd) {

                    img {
                        order: 1;
                    }

                    div {
                        order: 2;
                    }
                }

                &:nth-child(even) {

                    img {
                        order: 2;
                    }

                    div {
                        order: 1;
                    }
                }
            }
        }

        &.activity-sample__short-text-ordering,
        &.activity-sample__long-text-ordering {
            flex-direction: column;
            justify-content: center;
            gap: 0.4rem;
            padding: 1rem;
            color: $default-font-color;
            font-weight: 900;
            text-align: center;

            &::before {
                content: none; // Remove overlay color
            }

            > div {
                z-index: unset; // Unset .activity-sample__preview element default style
                min-width: unset; // Unset .activity-sample__preview element default style
                min-height: unset; // Unset .activity-sample__preview element default style
            }
        }

        &.activity-sample__long-text-ordering {
            
            > div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.activity-sample__missing-item {
            flex-direction: column;
            align-items: stretch;

            &::before {
                content: none; // Remove overlay color
            }

            > div {
                flex: none;
                z-index: unset; // Unset .activity-sample__preview element default style
                min-width: unset; // Unset .activity-sample__preview element default style
                height: 50%;
                min-height: unset; // Unset .activity-sample__preview element default style
                padding: 1rem;
                color: $default-font-contrast-color;

                &:first-child {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(0, 0.5fr));
                    grid-template-rows: minmax(0, 1fr);
                    justify-content: center;
                    justify-items: center;
                    align-items: center;
                    gap: 0.4rem;

                    > div {
                        position: relative;
                        overflow: hidden;
                        border: 1px rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

                        &::after {
                            content: '?';
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            inset: 0;
                            background-color: rgba($black-c, 0.5);
                            font-size: 2.5rem;
                            font-weight: 900;
                        }

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            aspect-ratio: 16/9;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }

                &:last-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;
                    background-color: $primary-c;
                    font-weight: 800;

                    mat-icon {
                        flex: none;
                        width: auto;
                        min-width: unset;
                        height: 50%;
                        min-height: 2rem;
                        max-height: 5rem;
                        aspect-ratio: 1;

                        svg {
                            width: 100%;
                            min-width: unset;
                            height: 100%;
                            min-height: unset;
                        }
                    }
                }
            }
        }

        &.activity-sample__recording,
        &.activity-sample__point-image {

            img,
            mat-icon {
                z-index: unset; // Unset .activity-sample__preview element default style
            }

            img {
                width: 100%;
                min-width: unset; // Unset .activity-sample__preview element default style
                height: 100%;
                min-height: unset; // Unset .activity-sample__preview element default style
                object-fit: cover;
                object-position: center;
            }

            mat-icon {
                position: absolute;
                z-index: 3;
                inset: 0;
                width: auto;
                min-width: unset; // Unset .activity-sample__preview element default style
                height: 35%;
                min-height: 3rem; // Override .activity-sample__preview element default style
                max-height: 6rem;
                aspect-ratio: 1;
                margin: auto;

                svg {
                    width: 100%;
                    min-width: unset;
                    height: 100%;
                    min-height: unset;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }
            }

            &.activity-sample__recording--missing-image,
            &.activity-sample__point-image--missing-image {
                color: $default-font-color;

                &::before {
                    content: none; // Remove overlay color
                }

                svg {
                    filter: unset;
                }
            }
        }

        &.activity-sample__external,
        &.activity-sample__tool {
            color: $default-font-color;

            &::before {
                content: none; // Remove overlay color
            }

            mat-icon {
                z-index: unset; // Unset .activity-sample__preview element default style
                flex: none;
                width: auto;
                min-width: unset; // Unset .activity-sample__preview element default style
                aspect-ratio: 1;

                svg {
                    width: 100%;
                    min-width: unset;
                    height: 100%;
                    min-height: unset;
                }
            }
        }

        &.activity-sample__external {

            mat-icon {
                height: 40%;
                min-height: 4rem; // Override .activity-sample__preview element default style
                max-height: 8rem;
            }
        }

        &.activity-sample__tool {
            flex-direction: column;
            gap: 0.5rem;

            mat-icon {
                height: 35%;
                min-height: 3rem; // Override .activity-sample__preview element default style
                max-height: 6rem;
            }

            span {
                z-index: unset; // Unset .activity-sample__preview element default style
                min-width: unset; // Unset .activity-sample__preview element default style
                min-height: unset; // Unset .activity-sample__preview element default style
                font-weight: 900;
            }
        }

        &.activity-sample__text-matching {
            position: relative;
            flex-direction: column;
            align-items: stretch;
            color: $default-font-contrast-color;

            &::before {
                content: none; // Remove overlay color
            }

            .activity-sample__text-matching-audio,
            .activity-sample__text-matching-image,
            .activity-sample__text-matching-text,
            .activity-sample__text-matching-icon {
                z-index: unset; // Unset .activity-sample__preview element default style
            }

            .activity-sample__text-matching-audio,
            .activity-sample__text-matching-image,
            .activity-sample__text-matching-text {
                flex: none;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                min-width: unset; // Unset .activity-sample__preview element default style
                height: 50%;
                min-height: unset; // Unset .activity-sample__preview element default style
                padding: 1rem;
                background-color: $primary-c;
                font-weight: 800;

                mat-icon {
                    flex: none;
                    width: auto;
                    min-width: unset;
                    height: 50%;
                    min-height: 2rem;
                    max-height: 5rem;
                    aspect-ratio: 1;

                    svg {
                        width: 100%;
                        min-width: unset;
                        height: 100%;
                        min-height: unset;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    }
                }
            }
            
            .activity-sample__text-matching-audio,
            .activity-sample__text-matching-text {

                + .activity-sample__text-matching-audio,
                + .activity-sample__text-matching-text {
                    background-color: rgba($primary-c, 0.9);
                }
            }

            .activity-sample__text-matching-image {
                position: relative;

                > div { // Image wrapper

                    &::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        background-color: rgba($black-c, 0.5);
                    }

                    img {
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                span {
                    position: relative;
                }
            }

            .activity-sample__text-matching-icon {
                position: absolute;
                inset: 0;
                width: auto;
                min-width: unset; // Unset .activity-sample__preview element default style
                height: 15%;
                min-height: 1.5rem; // Override .activity-sample__preview element default style
                max-height: 3rem;
                aspect-ratio: 1;
                margin: auto;

                svg {
                    width: 100%;
                    min-width: unset;
                    height: 100%;
                    min-height: unset;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }
            }
        }
    }

    .activity-sample__window-infos,
    .activity-sample__overlay-infos {
        z-index: 5;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        position: relative;
        padding: 5px;

        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #FFF;
        text-align: center;

        .activity-sample__preview {
            border: unset;
        }

        > *:not(.activity-sample__preview) {
            z-index: 20;
        }

        mat-icon {
            flex: none;
            width: auto;
            min-width: unset;
            height: 50%;
            min-height: 2rem;
            max-height: 5rem;
            aspect-ratio: 1;

            svg {
                width: 100%;
                min-width: unset;
                height: 100%;
                min-height: unset;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }
        }
    }

    .multi-media {
        flex-direction: row;
    }

    .activity-sample__window-infos {
        order: 1;

        &.activity-sample__multimedia-video {

            mat-icon {
                transform: scale(0.7);
            }
        }

        &.activity-sample__multimedia-text {

            > div {
                background-color: $primary-c;

                &::before { // Overlay gradient
                    background-color: unset;
                    background-image: linear-gradient(to bottom, rgba($primary-c, 0.5), $primary-c);
                }
            }
        }
    }

    .activity-sample__typology-trueFalse {
        flex-direction: row;

    }

    .activity-sample__overlay-infos {
        order: 2;
        background-color: $primary-c;
        z-index: 5;
    }

    .activity-sample__multiShortAnswersTemplate {
        justify-content: space-evenly;
        .mat-icon {
            width: 30px;
            height: unset;
        }
        span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        //.grid-multi-answers {
        //    flex: none;
        //}
    }

}