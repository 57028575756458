app-bd-list {

    .page-layout > .content {
        padding: 16px;
    }

    .mat-mdc-progress-spinner {

        svg {

            circle {
                stroke: var(--accent);
            }
        }
    }

    .bd-card-list {
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        max-height: 100%;
        place-content: flex-start;
        align-items: flex-start;

        .fake-card .mat-mdc-card {
            display: flex;
            justify-content: center;
            background-color: $accent-c;
            border-radius: 13.9941px;
            min-height: 278px;
            height: auto;
            color: white;
            .mat-mdc-card-content {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                margin-left: 0 !important;
                cursor: pointer;
            }
        }

        .mat-mdc-card-header
        {
            background-color: $primary-c !important;
            .mat-mdc-card-title *
            {
                color: white !important;
                text-transform: uppercase;
            }
        }

        .mat-mdc-card .mat-mdc-card-content, .mat-mdc-card .mat-mdc-card-actions  {
            margin-left: 244px;
        }
        .mat-mdc-card .mat-mdc-card-content  {
            flex: 1;
            .dl-listitem-inline dt, .dl-listitem-inline dd {
                display: inline;
                max-width: 70%;
            }
            img.mat-mdc-card-image {
                max-height: 250px;
            }
        }
    }

    /* mat-paginator {
        padding-right: 70px;
    } */
}