 app-dash-widget {

  .mat-mdc-card {
    padding: 0;
      min-width: 250px;

    .mat-mdc-card-header {
        padding: 10px;

      .mat-mdc-card-title {
        margin: 0;

          .mat-icon {
              margin-right: auto;
          }
      }

        .mat-mdc-icon-button {
            color: black;
            margin-left: auto;

            span {
                line-height: 0.9em;
                font-size: 0.9em;
            }

            .mat-icon path {
                fill: #000;
            }
        }
    }
  }
}


app-widget-forms-assigned,
app-widget-forms-closed,
app-widget-workgroup,
app-widget-forms,
app-widget-research-sheet-models,
app-widget-form-models,
app-dashwidget-project,
app-dashwidget-group,
app-widget-corpus,
app-widget-corpus-research-space,
app-widget-corpus-summary,
app-widget-research-sheet,
app-widget-section-draft
{
  min-height: 250px;
    width: 100%;
  .mat-mdc-table {
    td{
      &.mat-mdc-cell {
        border-bottom-color: #e7e7e7;
        padding: 0 20px 0 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }
  }
  .emptyResourceLabel {
    text-align: center;
      min-height: 100px;
  }
}


app-widget-workgroup,
app-dashwidget-project,
app-dashwidget-group,
app-widget-research-sheet-models,
app-widget-form-models,
app-widget-corpus-summary{
  width: 400px;
}

app-widget-forms-assigned,
app-widget-forms-closed,
app-widget-forms,
app-widget-corpus,
app-widget-corpus-research-space,
app-widget-research-sheet,
app-widget-section-draft{
  min-width: 640px;
}