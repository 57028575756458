app-assignment-select-seances {

    .assignment-select-seances {

        .assignment-select-seances__content {

            .assignment-select-seances__title {

                cite {
                    font-style: italic;
                    font-weight: 900;
                }
            }

            .assignment-select-seances__session-container {
                @include transparent-to-white-border;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding: 0.5rem;
                background-color: rgba($secondary-c, 0.15);
                border-radius: 12px;

                .assignment-select-seances__session-checkbox {
                    @include white-to-transparent-border;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem 1.5rem;
                    background-color: rgba(255, 255, 255, 0.75);
                    border-radius: 8px;
                    cursor: pointer;

                    &:has(:focus-visible) {
                        @include a11y-focus-visible;
                    }

                    input[type="checkbox"] {
                        @include visually-hidden;
                    }

                    .assignment-select-seances__session-label,
                    .assignment-select-seances__session-assignment {
                        font-weight: 700;
                    }

                    .assignment-select-seances__session-assignment {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        padding: 0.5rem 1rem;
                        color: $accent-c;
                        background-color: rgba($secondary-c, 0.05);
                        border-radius: 4px;
                        font-size: 1.4rem;

                        .assignment-select-seances__assignment-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: auto;
                            height: 2rem;
                            aspect-ratio: 1;
                            background-color: $off-white;
                            transition: background-color 100ms ease-in-out;

                            &::after {
                                content: '';
                                width: 80%;
                                height: 80%;
                                background-color: $off-white;
                                mask-image: icon('check.svg');
                                mask-size: contain;
                                mask-repeat: no-repeat;
                            }

                            &.assignment-select-seances__assignment-icon--checked {
                                background-color: $accent-c;
                            }
                        }
                    }
                }
            }
        }
    }
}