// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

$fake-card--background-color: null !default;
$fake-card--color: null !default;
$accent-c: null !default;
$default-font-color: null !default;
$card__header--breadcrumb--background-color: null !default;
$card__header--breadcrumb--color: null !default;

// --------------------------------------------------
// STYLE
// --------------------------------------------------

// ----- NOTEPAD-LIST

app-notepad-list {
    & > div.page-layout.simple {
        flex-basis: inherit; // remove auto
    }

    .notepad-card-list {
        flex: 1;

        > .content {
            @include mx-2;
            @include my-2;
            @include grid(
                $sm-col: 2,
                $sm-row: 1,
                $lg-col: 2,
                $lg-row: 1,
                $xl-col: 3,
                $xl-row: 1,
                $gap: 22
            );
        }


        .fake-card {
            @include fake-card;
        }
        .fake-card .mat-mdc-card {
            background-color: $fake-card--background-color;
            color: $fake-card--color;
        }
    }

    button[mat-fab].mat-mdc-fab.mat-mdc-fab-bottom-right {
        background-color: $accent-c;

        svg {
            path {
                stroke: $default-font-color;
            }
        }
    }
}

// ----- NOTEPAD-CARD

app-notepad-card {

    .mat-mdc-card {

        .mat-mdc-card-header {
            background-color: $card__header--breadcrumb--background-color !important;

            .mat-mdc-card-title * {
                color: $card__header--breadcrumb--color !important;
                text-transform: uppercase;
            }

            button {

                .mat-icon {
                    color: inherit;
                }
            }
        }
    }
}

// ----- NOTEPAD-DATA-EDITOR-MODAL

app-notepad-data-editor-modal {
    .mat-mdc-form-field-label {
        color: $default-font-color !important;
    }
}

// ----- NOTEPAD-CONTENT-EDITOR

app-notepad-content-editor {

    .page-layout {

        .content {

            .content-editor {
                height: calc(100vh - $main-toolbar--height);
                min-height: min-content;

                .notepad-header {
                    flex: none;
                }

                .notepad-content {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    height: unset; // Unset default theme style
                    min-height: 5rem;
                    padding: 0 2rem;
                }

                .notepad-action {
                    flex: none;
                    gap: 2rem;
                    padding: 3rem 2rem;

                    button {
                        margin: unset; // Unset default theme style
                    }
                }
            }
        }
    }
}